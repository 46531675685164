<template>
  <div class="dashboard">
    <Loader v-if="!assignment || !hasUser"/>
    <div class="dashboard__container" v-if="assignment && !hasUser">
      <div class="dashboard__container--header flex flex-column"> 
        <!-- <h1>This assignment has already been cancelled.</h1> -->
        <p>Contact your staffing manager if you need assistance.</p>
      </div>
    </div>
    <div class="dashboard__container" v-if="assignment && hasUser">
      <div class="dashboard__container--header" v-if="assignment && isCancelling"> 
        <h1>Are you sure you want to cancel?</h1>
      </div>

      <div v-if="assignment && hasUser && isCancelling">
        <p>You are currently scheduled to work at {{assignment.eventName}} on {{assignment.date}} as a {{assignment.position}} from {{assignment.shiftStart}} to {{assignment.shiftEnd}}.</p>
        <p>Please note, dropping a shift may affect your ability to work future events.</p>
        <div class="mb-3 mt-3">
          <button class="btn ma-3 btn__large btn__danger" @click="notAvailable()" v-tooltip="'Cancel'">Cancel My Shift
            
            <transition name="fade">
              <span class="ml-2" v-if="performingRequest">
              <i class="fa fa-spinner fa-spin"></i>
              </span>
            </transition>
          </button>
          <button class="btn ma-3 btn__large btn__success" @click="available()" v-tooltip="'Confirm'">I Can Still Work
            
            <transition name="fade">
              <span class="ml-2" v-if="performingRequest2">
              <i class="fa fa-spinner fa-spin"></i>
              </span>
            </transition>
          </button>
        </div>
      </div>
      <div v-if="assignment && assignmentUser && !isCancelling && !isCancelled">
        <p>We'll see you at {{assignment.eventName}} from {{assignment.shiftStart}} to {{assignment.shiftEnd}}.</p>
      </div>
      <div v-if="(assignmentUser && assignmentUser.confirmed) && !isCancelling && !isCancelled">
        <h1>You made the right choice</h1>
        <p>See you there!</p>
      </div>
      <div v-if="isCancelled">
        <h1>Thank you for letting us know.</h1>
        <p>Let's catch the next one!</p>
      </div>
    </div>
  </div>
</template>
<style scoped>
  .ep-container {
    margin: 0.5rem 0;
  }
  button{
      margin-top:2em;
  }
</style>
<script>
import { mapState } from 'vuex' 
import Loader from '@/components/Loader.vue'
const fb = require('../firebaseConfig.js')

export default {
  name: 'cancel',
  data: () => ({
    performingRequest: false,
    performingRequest2: false,
    isCancelling: true, // this sets the default condition that shows the buttons
    isCancelled: false // to change content after cancel button pressed
  }),
  components: {
    Loader
  },
  created() {
    this.$store.dispatch("getAssignmentFromId", this.$route.params.id)
  },
  computed: {
    ...mapState(['assignmentUser', 'assignment']),
    hasUser() {
      return this.assignmentUser.hasOwnProperty('id')
    }
  },
   methods: {
    // notAvailable(){
    //   this.performingRequest = true
    //   fb.userDaysCollection.doc(this.assignmentUser.id).delete()
    //   setTimeout(() => {
    //     this.performingRequest = false
    //     console.log('deleted')
    //     this.isCancelling = false
    //     this.isCancelled = true
    //   }, 1000)
    // },

    notAvailable() {
      this.performingRequest = true
      this.$store.dispatch('deleteUserDay', this.assignment)
      setTimeout(() => {
        this.isCancelling = false
        this.isCancelled = true
        this.performingRequest = false
      }, 1000)
    },

    available() {
      this.performingRequest2 = true
      fb.userDaysCollection.doc(this.assignmentUser.id).update({
        confirmed: true
      })
      setTimeout(() => {
        this.performingRequest2 = false
        console.log('confirmed')
        this.isCancelling = false
      }, 1000)
    },
   },
   beforeDestroy () {
    this.performingRequest = null
    delete this.performingRequest
    this.$store.dispatch('clearAssignmentId')
  }
}
</script>